import React, { useState } from "react";
const contents = [
  {
    text: "Metodbox tüm K12 okullarının öğrencileri, öğretmenleri, okul yöneticileri ve üye öğrencilerin velileri tarafından kullanılan; hem web sitesi, hem mobil sitesi hem de IOS ve Android uygulamaları sayesinde, her yerde bağlanılabilen ve kullanılabilen bir eğitim platformudur.",
    link: "https://www.metodbox.com/",
    btnText: "Metodbox'a Git",
  },
  {
    text: "Metodbox Bee, K1-4 eğitim seviyesinde işlenen proje tabanlı derslerde kullanılabilen, süreç değerlendirmesine olanak sağlayan proje yönetim ve değerlendirme platformudur. Öğrenciler tabletten, öğretmen ve yöneticiler webden kullanabilirler",
    link: "https://www.mbxbee.com/",
    btnText: "MetodboxBee'ye Git",
  },
  {
    text: "SeeMeet, çevrimiçi tarayıcılar / uygulamalar aracılığı ile kullanabileceğiniz bir sosyal etkileşim, toplantı uygulamasıdır. SeeMeet'i tüm bireysel görüşme ihtiyaçlarınız için ücretsiz olarak kullanabilirsiniz. Bir dakikadan daha kısa süre içerisinde üye olabileceğiniz.",
    link: "https://www.seemeet.live/",
    btnText: "SeeMeet'e Git",
  },
];

const icons = [
  "img/METODBOX-TURUNCU.svg",
  "img/metoboxbee_logo_beyaz kanat 1.svg",
  "img/seemeet_logo_800pb 1.svg",
];
const Products = () => {
  const [currContent, setCurrContent] = useState(contents[0]);
  const setActive = (e) => {
    const active = document.getElementsByClassName(
      "responsive-img col-3 active"
    )[0];
    active.classList.remove("active");
    e.target.classList.add("active");
  };
  return (
    <div className="main-container" style={{ padding: "85px", height: "100%" }}>
      <div className="section-container static-page ">
        <div className="row">
          <div className="limited col col-l-6" style={{ margin: "auto 5%" }}>
            <header className="icon-header">
              <p>Diğer Ürünlerimiz</p>
            </header>

            <div className="row">
              {icons.map((icon, index) => (
                <img
                  src={icon}
                  className={
                    index === 0
                      ? "responsive-img grey-img col-3 active"
                      : "responsive-img grey-img col-3"
                  }
                  key={index}
                  alt="logo"
                  onClick={(e) => {
                    setActive(e);
                    setCurrContent(contents[index]);
                  }}
                ></img>
              ))}
            </div>
            <div>
              <div style={{ marginTop: "3%" }}>{currContent.text}</div>
              <button
                style={{ marginTop: "3%" }}
                className="btn btn-light"
                onClick={() => window.open(currContent.link, "_blank")}
              >
                {currContent.btnText}
              </button>

              {/* <button className="btn btn-light" onClick={()=> history. } >{currContent.btnText}</button> */}
            </div>
          </div>
          <div
            className="col-12 col-md"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <img
              className="responsive-img"
              src="img/Group 22.png"
              alt="img"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
