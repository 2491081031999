import React, { useEffect, useState } from "react";
import { runGeo } from "./geo_script";
import { runEye } from "./eye_script";
import { runWorld } from "./world_script";
import { runDrop } from "./drop_script";
import { DocumentScanner } from "@mui/icons-material";
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const Landing = (props, context) => {
  const sections = [
    { index: 0, id: "geoWrapper" },
    { index: 1, id: "worldwrapper" },
    { index: 2, id: "eyewrapper" },
    { index: 3, id: "dropwrapper" },
  ];
  const infoGrids = [
    { title: "Kişiye özel içerikler", src: "img/hover1.png" },
    { title: "Özgün ve eğlenceli uygulamalar", src: "img/hover2.png" },
    { title: "Yeni ve kişisel öğrenme deneyimi", src: "img/hover3.png" },
    {
      title: "Öğrenme motivasyonunu arttırıcı ortamlar",
      src: "img/hover4.png",
    },
    { title: "Fotoğraf ve video çekme", src: "img/hover5.png" },
    { title: "Ders, ünite ve konu detayları", src: "img/hover6.png" },
  ];
  const size = useWindowSize();
  const [currSection, setCurrSection] = useState(sections[0]);
  useEffect(() => {
    runGeo();
    runEye();
    runWorld();
    runDrop();
  }, []);

  useEffect(() => {
    if (currSection !== null) {
      document.getElementById(currSection.id).scrollIntoView();
    }
  }, [size]);

  setTimeout(() => {
    const downButton = document.getElementById("download-btn");
    downButton.addEventListener("click", () => {
      scrollTo(sections[3]);
    });
  }, 500);

  const scrollTo = (section) => {
    document.getElementsByClassName("active dot")[0].classList.remove("active");
    document.getElementById(section.id).scrollIntoView();
    setCurrSection(section);
    const dots = document.getElementsByClassName("dot-link");
    dots[section.index].lastElementChild.classList.add("active");
  };
  const move = (e, direction) => {
    document.getElementsByClassName("active dot")[0].classList.remove("active");
    const dots = document.getElementsByClassName("dot-link");
    const index =
      direction === "left" ? currSection.index - 1 : currSection.index + 1;
    const prevItem = Math.floor(sections.length + index) % sections.length;
    setCurrSection(sections[prevItem]);
    dots[prevItem].lastElementChild.classList.add("active");
    document
      .getElementById(sections[prevItem].id)
      .scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="main-container">
      <section className="sectionContainer" id="geoWrapper">
        <div
          className="three-container"
          id="geoContainer"
          style={{ margin: 0 }}
        >
          <canvas className="webgl2"></canvas>
        </div>
        <div className="info">
          <div className="content">
            <header>
              <img src="img/logo.svg" alt="Metodbox AR" className="info-logo" />
              nedir?
            </header>
            <p>
              Metodbox AR, K12 düzeylerindeki öğrencilerin tüm ders, ünite ve
              konulardaki AR{" "}
              <strong>(Augmented Reality-Artırılmış Gerçeklik)</strong>{" "}
              içeriklerine aynı platformdan kolayca erişebilecekleri,
            </p>
            <div style={{ display: "flex" }}>
              <img src="img/section-icon.svg" alt="icon"></img>
              <p style={{ marginLeft: "14px" }}>
                <span
                  className="highlighted-text"
                  style={{ display: "flex", margin: 0, width: "100%" }}
                >
                  Eğitim odaklı Arttırılmış gerçeklik
                </span>
                uygulamasıdır.
              </p>
            </div>
            <p>
              Metodbox AR sayesinde gerçek dünyayla sanal dünyanın bir arada
              olduğu yepyeni öğrenme deneyimini keşfet.
            </p>

            {/* <div className="mbx-ar-download mobile">
              <a href="javascreipt:;">
                <img src="img/app-store.png" alt="AppStore" height="42" />
              </a>
              <a href="javascreipt:;">
                <img src="img/google-play.png" alt="Google Play" height="42" />
              </a>
            </div> */}
          </div>
        </div>
      </section>
      <section className="sectionContainer" id="worldwrapper">
        <div className="info left-align">
          <div className="content limited">
            <header>
              <img src="img/logo.svg" alt="Metodbox AR" className="info-logo" />
              deneyimi
            </header>
            <p>
              Kendi düzeyine uygun, birbirinden farklı içeriklerle yeni
              deneyimler yaşa.
            </p>
            <div style={{ display: "flex", marginBottom: "2%" }}>
              <img src="img/section-icon-2.svg" alt="icon"></img>
              <p
                style={{
                  marginLeft: "14px",
                  fontSize: "large",
                  marginBottom: 0,
                  maxWidth: "60%",
                  color: "rgba(255, 255, 255, 1)",
                }}
              >
                Sesli ve yazılı açıklamalar ile desteklenmiş 3D içerikleri ile
                eğlenirken öğren.
              </p>
            </div>{" "}
            <p>Sanal dünyayla kendi dünyanı birleştir.</p>
            <p>
              Bütün bunları yaparken, çektiğin fotoğraf veya video ile
              deneyimlerini başkalarıyla paylaş.
            </p>
          </div>
        </div>
        <div className="three-container" id="worldcontainer">
          <canvas className="webgl3"></canvas>
        </div>
      </section>
      <section className="sectionContainer" id="eyewrapper">
        <div
          className="three-container"
          id="eyecontainer"
          style={{ margin: 0 }}
        >
          <canvas className="webgl1"></canvas>
        </div>
        <div className="info">
          <div className="content">
            <header>
              <img src="img/logo.svg" alt="Metodbox AR" className="info-logo" />
              farkı
            </header>
            <div className="cardContainer">
              <div className="row">
                {infoGrids.map((info, index) => (
                  <div className="col-4 cardGrid" key={index}>
                    <div className="cardGrid-item">
                      {info.title}
                      <img src={info.src} alt="Frame" className="frame-img" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="sectionContainer"
        id="dropwrapper"
        data-anchor="fourthSection"
      >
        <div className="info left-align">
          <div className="content limited">
            <header>
              <img src="img/logo.svg" className="info-logo" alt="Metodbox AR" />
              şimdi dene
            </header>
            <p>
              Mobil cihazınla arttırılmış gerçeklik dünyasına giriş yap.{" "}
              <strong style={{ color: "rgba(255, 255, 255, 1)" }}>
                Tablet veya cep telefonuna
              </strong>{" "}
              MbxAR uygulamasını hemen yükle.
            </p>
            <p>Gerçeğe yakın deneyimlerle eğlenirken öğrenmeye hemen başla!</p>

            <div className="mbx-ar-download qr-container row">
              <a
                className="col-6"
                href="https://mbx.mx/mbxar"
                style={{ padding: "0 2% 0 0" }}
              >
                <img
                  src="img/app-store.png"
                  alt="AppStore"
                  className="img-fit"
                />
              </a>
              <a
                className="col-6"
                href="https://mbx.mx/mbxar"
                style={{ padding: "0 2% 0 0" }}
              >
                <img
                  src="img/google-play.png"
                  alt="Google Play"
                  className="img-fit"
                />

                {/* <img
                  src="img/qr-google.png"
                  alt=""
                  className="img-auto-heigth img-fit"
                /> */}
              </a>
              <div className="col-md-12">
                {" "}
                <img
                  src="img/qr-both.jpeg"
                  alt=""
                  className="img-auto-heigth img-fit"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="three-container" id="dropcontainer">
          <canvas className="webgl4"></canvas>
        </div>
      </section>

      <nav className="main-nav">
        <ul>
          <li onClick={(e) => move(e, "left")}>
            <div className="dot" id="moveLeft">
              <img src="img/arrow-left.svg" alt="Geri" />
            </div>
          </li>
          {sections.map((item) => (
            <li
              className="dot-link"
              onClick={() => scrollTo(item)}
              key={item.index}
            >
              <div
                className={(item.index === 0 ? "active" : "") + " dot"}
              ></div>
            </li>
          ))}
          <li onClick={(e) => move(e, "right")}>
            <div className="dot" id="moveRight">
              <img src="img/arrow-right.svg" alt="Ileri" />
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

Landing.contextTypes = {};
export default Landing;
