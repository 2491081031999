import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import CANNON from "cannon";

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()

export const runDrop = () => {
  // Canvas
  const canvas = document.querySelector("canvas.webgl4");
  const container = document.getElementById("dropcontainer");
  const section = document.getElementById("dropwrapper");

  // Scene
  const size1 = 1;
  const size2 = 1;
  const scene = new THREE.Scene();

  /**
   * Sounds
   */
  // const hitSound = new Audio("./sounds/drop/hit.mp3");

  // const playHitSound = (collision) => {
  //   const impactStrength = collision.contact.getImpactVelocityAlongNormal();

  //   if (impactStrength > 1.5) {
  //     //hitSound.volume = Math.random();
  //     //hitSound.currentTime = 0;
  //     //hitSound.play();
  //   }
  // };

  /**
   * Physics
   */
  // World
  const world = new CANNON.World();
  world.broadphase = new CANNON.SAPBroadphase(world);
  world.allowSleep = true;
  world.gravity.set(0, -15.82, 0);

  // Materials
  const defaultMaterial = new CANNON.Material("default");
  const defaultContactMaterial = new CANNON.ContactMaterial(
    defaultMaterial,
    defaultMaterial,
    {
      friction: 0.9,
      restitution: 0.3,
    }
  );
  world.addContactMaterial(defaultContactMaterial);
  world.defaultContactMaterial = defaultContactMaterial;

  /**
   * Models
   */
  const gltfLoader = new GLTFLoader();

  const group = new THREE.Group();

  const clock = new THREE.Clock();

  // Load Phone glb
  gltfLoader.load("./models/drop/phone.glb", (phone) => {
    const phoneMesh = phone.scene.children[0];
    var bb = new THREE.Box3();
    bb.setFromObject(phoneMesh);
    bb.getCenter(controls.target);
    phone.scene.scale.set(30, 30, 30);
    phoneMesh.position.y = 0.2;
    phoneMesh.position.x += 0.005;
    group.add(phone.scene);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      phone.scene.position.y = Math.cos(elapsedTime * 3) * 0.1;

      window.requestAnimationFrame(tick);
    };
    tick();
  });

  // Load Card glb
  gltfLoader.load("./models/drop/card.glb", (card1) => {
    const mesh = card1.scene.children[0];
    mesh.position.x = 0.008;
    mesh.position.y = 0.165;
    mesh.position.z = 0.03;
    card1.scene.scale.set(35, 35, 35);
    group.add(card1.scene);
    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      card1.scene.position.y = Math.cos(elapsedTime * 3) * 0.1;
      card1.scene.position.z = Math.cos(elapsedTime * 3) * 0.05;

      window.requestAnimationFrame(tick);
    };

    tick();
  });

  // Load Tablet glb
  gltfLoader.load("./models/drop/tablet.glb", (tablet) => {
    const tabletMesh = tablet.scene.children[0];
    tabletMesh.position.y = 0.2;
    tabletMesh.position.x += 0.005;
    tablet.scene.scale.set(25, 25, 25);

    group.add(tablet.scene);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      tablet.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;

      window.requestAnimationFrame(tick);
    };
    tick();
  });

  group.scale.set(2.4, 2.4, 2.4);
  group.position.y = -16;
  group.position.x = -3;
  scene.add(group);

  let mixer = null;

  // Boxes
  const boxGeometry = new THREE.BoxBufferGeometry(1, 1, 1);

  // Floor
  const floorShape = new CANNON.Plane();
  const floorBody = new CANNON.Body();
  floorBody.mass = 0;
  floorBody.addShape(floorShape);
  floorBody.quaternion.setFromAxisAngle(
    new CANNON.Vec3(-1, 0, 0),
    Math.PI * 0.5
  );
  floorBody.position.y = -12;

  world.addBody(floorBody);

  const createBox = (edges, position) => {
    // Three.js mesh
    const boxMaterial = new THREE.MeshStandardMaterial({
      metalness: 0.3,
      roughness: 0.3,
    });
    boxMaterial.color = new THREE.Color("#FCB026");
    const mesh = new THREE.Mesh(boxGeometry, boxMaterial);
    mesh.scale.set(edges * 3, edges * 3, edges * 3);
    mesh.castShadow = false;
    mesh.position.copy(position);
    scene.add(mesh);

    // CANON.js body
    const shape = new CANNON.Box(
      new CANNON.Vec3(edges * 1.5, edges * 1.5, edges * 1.5)
    );
    const body = new CANNON.Body({
      mass: 10,
      position: new CANNON.Vec3(0, 10, 0),
      shape,
      material: defaultMaterial,
    });
    body.position.copy(position);
    // body.addEventListener("collide", playHitSound);
    world.addBody(body);

    // Save in objects to update
    objectsToUpdate.push({
      mesh,
      body,
    });

    // Remove Created Object After 10 seconds
    setTimeout(function () {
      // Remove body
      // body.removeEventListener("collide", playHitSound);
      world.removeBody(body);

      // Remove mesh
      scene.remove(mesh);
    }, 10000);
  };

  /**
   * Lights
   */
  // Ambient Light
  const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
  scene.add(ambientLight);

  // Directional Light
  const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
  directionalLight.position.set(8.13, 1.31, 8.13);
  // directionalLight.scale.set(0.75,0.75,0.75)
  // gui.add(directionalLight, 'intensity').min(0).max(1).step(0.001)
  // gui.add(directionalLight.position, 'x').min(- 5).max(10).step(0.001)
  // gui.add(directionalLight.position, 'y').min(- 5).max(10).step(0.001)
  // gui.add(directionalLight.position, 'z').min(- 5).max(10).step(0.001)
  // const helper = new THREE.DirectionalLightHelper( directionalLight, 5 );
  // scene.add( helper );
  scene.add(directionalLight);

  /**
   * Sizes
   */
  const sizes = {
    width: container.offsetWidth / size1,
    height: container.offsetHeight * size2,
  };

  window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = container.offsetWidth;
    sizes.height = container.offsetHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });

  /**
   * Camera
   */
  // Base camera
  const camera = new THREE.PerspectiveCamera(
    75,
    sizes.width / sizes.height,
    0.1,
    50
  );
  // camera.position.set(5, 7 , 20);
  scene.add(camera);

  // Controls
  const controls = new OrbitControls(camera, canvas);
  controls.enableRotate = false;
  controls.enablePan = false;
  controls.enableZoom = false;
  controls.target.set(1.75, 0.75, 0);
  controls.enableDamping = false;

  /**
   * Renderer
   */
  const renderer = new THREE.WebGLRenderer({
    alpha: true,
    canvas: canvas,
    antialias: true,
  });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.setClearColor(0x000000, 0);

  /**
   * Utils
   */
  const objectsToUpdate = [];

  /**
   * Shadows
   */
  renderer.shadowMap.enabled = false;
  // renderer.shadowMap.type = THREE.PCFSoftShadowMap;

  directionalLight.castShadow = false;
  directionalLight.shadow.mapSize.set(512, 512);
  directionalLight.shadow.camera.far = 15;
  directionalLight.shadow.camera.left = -7;
  directionalLight.shadow.camera.top = 7;
  directionalLight.shadow.camera.right = 7;
  directionalLight.shadow.camera.bottom = -7;

  // Pointer Move Event
  section.addEventListener("pointermove", onpointermove, false);

  const mouse = new THREE.Vector2();

  function onpointermove(event) {
    mouse.x = (event.clientX / section.offsetWidth) * 2 - 1;
    mouse.y = -(event.clientY / section.offsetHeight) * 2 + 1;
  }

  // Pointer Down Event
  section.addEventListener("pointerdown", onpointerdown, false);

  function onpointerdown(event) {
    event.preventDefault();

    createBox(0.8, {
      x: Math.random() * 10,
      y: Math.random() * 3 + 15,
      z: (Math.random() - 0.5) * 15,
    });
  }

  /**
   * Animate
   */
  let oldElapsedTime = 0;

  const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - oldElapsedTime;
    oldElapsedTime = elapsedTime;

    //Parallax Animation
    camera.position.set(6 + -mouse.x * 5, 7 + -mouse.y * 5, 18);
    controls.target.set(2.25, 0.75, 0);

    world.step(1 / 60, deltaTime, 3);

    for (const object of objectsToUpdate) {
      object.mesh.position.copy(object.body.position);
      object.mesh.quaternion.copy(object.body.quaternion);
    }

    if (mixer != null) {
      mixer.update(deltaTime);
    }

    // Update controls
    controls.update();

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
  };

  tick();
};
