import React from "react";
import { useHistory } from "react-router-dom";
import Header from "../../Components/Header";
// import Sidebar from "../../components/Sidebar";
import MainRouter from "../../router/MainRouter";

const Home = () => {
  const history = useHistory();
  return (
    <>
      <div className="at-background-bg" />
      <Header />
      <div className="rising-sun"></div>
      <MainRouter />
    </>
  );
};

export default Home;
