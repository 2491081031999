import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()

export const runEye = () => {
  // Canvas
  const canvas = document.querySelector("canvas.webgl1");
  const container = document.getElementById("eyecontainer");
  const section = document.getElementById("eyewrapper");
  // container.setAttribute(
  //   "style",
  //   "height:" + (window.innerHeight - 300) + "px"
  // );

  const size1 = 1;
  const size2 = 1;

  // Scene
  const scene = new THREE.Scene();

  /**
   * Models
   */
  const gltfLoader = new GLTFLoader();

  const clock = new THREE.Clock();
  const group = new THREE.Group();

  let mixer = null;

  // Load Phone glb

  gltfLoader.load("./models/eye/phone.glb", (phone) => {
    const phoneMesh = phone.scene.children[0];
    var bb = new THREE.Box3();
    bb.setFromObject(phoneMesh);
    bb.getCenter(controls.target);
    phone.scene.scale.set(50, 50, 50);
    phoneMesh.position.y = -0.1;

    group.add(phone.scene);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      phone.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;

      window.requestAnimationFrame(tick);
    };
    tick();
  });

  // Load Eye glb
  gltfLoader.load("./models/eye/goz.glb", (eye) => {
    const mesh = eye.scene.children[0];
    mesh.position.set(0, 0, 6);
    mixer = new THREE.AnimationMixer(eye.scene);
    const clips = eye.animations;

    eye.scene.scale.set(0.5, 0.5, 0.5);

    // Pointer Down Event
    var clickCount = 0;

    function incrementCount() {
      clickCount++;
    }
    function first(event) {
      event.preventDefault();
      if (clickCount % 2 === 1) {
        clips.forEach(function (clip) {
          mixer.clipAction(clip).play();
        });
      }
    }

    function second() {
      if (clickCount % 2 === 0) {
        clips.forEach(function (clip) {
          mixer.clipAction(clip).stop();
        });
      }
    }
    section.addEventListener("click", incrementCount);
    section.addEventListener("click", first);
    section.addEventListener("click", second);

    // Pointer Up Event
    // window.addEventListener("pointerup", onpointerup, false);

    // function onpointerup(event) {
    //   event.preventDefault();
    //   clips.forEach(function (clip) {
    //     mixer.clipAction(clip).stop();
    //   });

    // }

    group.add(eye.scene);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      eye.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;

      window.requestAnimationFrame(tick);
    };
    tick();
  });

  // Load Logo glb
  gltfLoader.load("./models/eye/logo.glb", (logo) => {
    logo.scene.scale.set(50, 50, 50);
    var box = new THREE.Box3().setFromObject(logo.scene);
    box.getCenter(logo.scene.position);
    logo.scene.position.multiplyScalar(-1);

    var pivot = new THREE.Group();
    group.add(pivot);
    pivot.add(logo.scene);
    pivot.position.set(4, -6, -2.5);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      logo.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;

      pivot.rotation.y = Math.sin(elapsedTime * 1.5) * 0.75;

      window.requestAnimationFrame(tick);
    };
    tick();
  });
  group.scale.set(1.2, 1.2, 1.2);
  group.position.y += 0.5;
  group.rotation.y = -Math.PI * 0.1;
  scene.add(group);

  /**
   * Lights
   */
  // Ambient Light
  const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
  scene.add(ambientLight);

  // Directional Light
  const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
  directionalLight.position.set(15, 11.31, 8.13);
  scene.add(directionalLight);

  /**
   * Sizes
   */
  const sizes = {
    width: container.offsetWidth / size1,
    height: container.offsetHeight * size2,
  };

  window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = container.offsetWidth;
    sizes.height = container.offsetHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });

  /**
   * Camera
   */
  // Base camera
  const camera = new THREE.PerspectiveCamera(
    75,
    sizes.width / sizes.height,
    0.1,
    50
  );
  camera.position.set(-5, 5, 9);
  scene.add(camera);

  // Controls
  const controls = new OrbitControls(camera, canvas);
  controls.enableRotate = false;
  controls.enablePan = false;
  controls.enableZoom = false;
  controls.target.set(1.75, 0.75, 0);
  controls.enableDamping = false;

  /**
   * Renderer
   */
  const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true,
  });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.setClearColor(0x000000, 0);

  // Pointer Move Event
  section.addEventListener("pointermove", onpointermove, false);

  const mouse = new THREE.Vector2();

  function onpointermove(event) {
    mouse.x = (event.clientX / section.offsetWidth) * 2 - 1;
    mouse.y = -(event.clientY / section.offsetHeight) * 2 + 1;
  }

  /**
   * Animate
   */
  let oldElapsedTime = 0;

  const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - oldElapsedTime;
    oldElapsedTime = elapsedTime;

    //Parallax Animation
    camera.position.set(-6 + -mouse.x * 5, 5 + -mouse.y * 4, 12);
    // controls.target.set(1.75,0.75,0)

    if (mixer != null) {
      mixer.update(deltaTime);
    }

    // Update controls
    controls.update();

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
  };

  tick();
};
