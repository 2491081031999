import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import gsap from "gsap";

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()
export const runGeo = () => {
  // Canvas
  const canvas = document.querySelector("canvas.webgl2");
  const container = document.getElementById("geoContainer");

  const section = document.getElementById("geoWrapper");

  // Scene
  const scene = new THREE.Scene();
  const group = new THREE.Group();
  const geoGroup = new THREE.Group();
  /**
   * Models
   */
  const gltfLoader = new GLTFLoader();

  const clock = new THREE.Clock();

  // Load Book GLTF
  gltfLoader.load("./models/geo/kitap/kitap.gltf", (book) => {
    book.scene.traverse(function (node) {
      if (node.isMesh) {
        node.receiveShadow = true;
        node.castShadow = true;
      }
    });
    book.scene.receiveShadow = true;
    book.scene.scale.set(40, 40, 40);
    book.scene.position.z = 0;
    group.add(book.scene);
  });

  // Load Pen glTF
  gltfLoader.load("./models/geo/kalem/kalem.gltf", (pen) => {
    pen.scene.traverse(function (node) {
      if (node.isMesh) {
        node.castShadow = true;
        node.receiveShadow = true;
      }
    });
    pen.scene.scale.set(4, 4, 4);
    pen.scene.position.z = 0;
    group.add(pen.scene);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      pen.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;
      pen.scene.rotation.y = Math.sin(elapsedTime * 0.15) * Math.PI * 0.15;

      window.requestAnimationFrame(tick);
    };

    tick();
  });

  // Load Phone glTF
  gltfLoader.load("./models/geo/telefon/phone.glb", (phone) => {
    phone.scene.traverse(function (node) {
      if (node.isMesh) {
        node.castShadow = true;
        node.receiveShadow = true;
      }
    });
    const mesh = phone.scene.children[0];
    mesh.receiveShadow = true;
    mesh.position.x = 0.03;
    mesh.position.y = 0.12;
    mesh.position.z = -0.12;
    phone.scene.scale.set(40, 40, 40);
    group.add(phone.scene);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      phone.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;

      window.requestAnimationFrame(tick);
    };

    tick();
  });

  // Load Matematik glTF
  gltfLoader.load("./models/geo/matematik/mat.glb", (matematik) => {
    const mesh = matematik.scene.children[0];
    mesh.castShadow = true;
    mesh.position.set(-0.015, 0.23, -0.018);
    matematik.scene.scale.set(40, 40, 40);
    group.add(matematik.scene);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      matematik.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;
      matematik.scene.position.z = Math.cos(elapsedTime * 3) * 0.05;

      window.requestAnimationFrame(tick);
    };

    tick();
  });

  // Load Turkce glTF
  gltfLoader.load("./models/geo/turkce/tr.glb", (turkce) => {
    turkce.scene.traverse(function (node) {
      if (node.isMesh) {
        node.castShadow = true;
      }
    });
    const mesh = turkce.scene.children[0];
    mesh.castShadow = true;
    mesh.position.set(0.06, 0.24, -0.01);
    turkce.scene.scale.set(40, 40, 40);
    group.add(turkce.scene);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      turkce.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;
      turkce.scene.position.z = Math.sin(elapsedTime * 3) * 0.05;

      window.requestAnimationFrame(tick);
    };

    tick();
  });
  scene.add(group);
  group.scale.set(0.9, 0.9, 0.9);
  group.position.y = -2.5;

  // Dodecahedron
  const dodoGeometry = new THREE.DodecahedronGeometry(10, 0);
  const dodoMaterial = new THREE.MeshStandardMaterial({ color: "#FCB026" });
  const dodo = new THREE.Mesh(dodoGeometry, dodoMaterial);
  dodo.position.set(1, 4, -2);
  dodo.scale.set(0.05, 0.05, 0.05);
  geoGroup.add(dodo);
  dodo.visible = false;

  // Cubes
  const cubeGeometry = new THREE.BoxGeometry(10, 10, 10);
  const cubeMaterial = new THREE.MeshStandardMaterial({ color: "#FCB026" });

  const cube1 = new THREE.Mesh(cubeGeometry, cubeMaterial);
  cube1.position.set(1, 4 - 2);
  cube1.scale.set(0.075, 0.075, 0.075);
  geoGroup.add(cube1);
  cube1.visible = false;

  const cube2 = new THREE.Mesh(cubeGeometry, cubeMaterial);
  cube2.position.set(1, 4 - 2);
  cube2.scale.set(0.05, 0.05, 0.05);
  geoGroup.add(cube2);
  cube2.visible = false;

  // Torus
  const torusGeometry = new THREE.TorusGeometry(10, 5, 16, 100);
  const torusMaterial = new THREE.MeshStandardMaterial({ color: "#F05029" });

  const donut = new THREE.Mesh(torusGeometry, torusMaterial);
  donut.position.set(1, 4 - 2);
  donut.scale.set(0.05, 0.05, 0.05);
  geoGroup.add(donut);
  donut.visible = false;

  geoGroup.scale.set(1.5, 1.5, 1.5);
  scene.add(geoGroup);

  /**
   * Lights
   */
  // Ambient Light
  const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
  scene.add(ambientLight);

  // Directional Light
  const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
  directionalLight.position.set(5.862, 5.374, 4.399);
  scene.add(directionalLight);

  /**
   * Sizes
   */
  const sizes = {
    width: container.offsetWidth,
    height: container.offsetHeight,
  };

  window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = container.offsetWidth;
    sizes.height = container.offsetHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });

  /**
   * Camera
   */
  // Base camera
  const camera = new THREE.PerspectiveCamera(
    75,
    sizes.width / sizes.height,
    0.1,
    100
  );
  camera.position.set(-5, 7, 8);
  scene.add(camera);

  // Controls
  const controls = new OrbitControls(camera, canvas);
  controls.enableRotate = false;
  controls.enablePan = false;
  controls.enableZoom = false;
  controls.target.set(0, 0.75, 0);
  controls.enableDamping = false;

  /**
   * Renderer
   */
  const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true,
  });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.setClearColor(0x000000, 0);

  /**
   * Shadows
   */
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;

  directionalLight.castShadow = true;
  directionalLight.shadow.mapSize.set(512, 512);
  directionalLight.shadow.camera.far = 15;
  directionalLight.shadow.camera.left = -7;
  directionalLight.shadow.camera.top = 7;
  directionalLight.shadow.camera.right = 7;
  directionalLight.shadow.camera.bottom = -7;

  // dodo.castShadow = true;
  // cube1.castShadow = true;
  // cube2.castShadow = true;
  // donut.castShadow = true;

  // Pointer Move Event
  section.addEventListener("pointermove", onpointermove, false);

  const mouse = new THREE.Vector2();

  function onpointermove(event) {
    mouse.x = (event.clientX / section.offsetWidth) * 2 - 1;
    mouse.y = -(event.clientY / section.offsetHeight) * 2 + 1;
  }

  var clickCount = 0;

  function incrementCount() {
    clickCount++;
  }
  function first(event) {
    event.preventDefault();
    if (clickCount % 2 === 1) {
      dodo.visible = true;
      cube1.visible = true;
      cube2.visible = true;
      donut.visible = true;

      gsap.to(dodo.position, { duration: 0.3, delay: 0.01, x: 2, y: 5, z: 3 });
      gsap.to(cube1.position, {
        duration: 0.3,
        delay: 0.01,
        x: -3,
        y: 2,
        z: 2.5,
      });
      gsap.to(cube2.position, {
        duration: 0.3,
        delay: 0.01,
        x: -1.5,
        y: 3,
        z: -2,
      });
      gsap.to(donut.position, {
        duration: 0.3,
        delay: 0.01,
        x: -2,
        y: 6,
        z: -3,
      });
    }
  }

  function second() {
    if (clickCount % 2 === 0) {
      gsap.to(dodo.position, { duration: 0.5, delay: 0.02, x: 1, y: 3, z: -2 });
      gsap.to(cube1.position, {
        duration: 0.5,
        delay: 0.02,
        x: 1,
        y: 3,
        z: -2,
      });
      gsap.to(cube2.position, {
        duration: 0.5,
        delay: 0.02,
        x: 1,
        y: 3,
        z: -2,
      });
      gsap.to(donut.position, {
        duration: 0.5,
        delay: 0.02,
        x: 1,
        y: 3,
        z: -2,
      });
    }
  }
  section.addEventListener("click", incrementCount);
  section.addEventListener("click", first);
  section.addEventListener("click", second);

  /**
   * Animate
   */
  const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    //Parallax Animation
    camera.position.set(-4 + -mouse.x * 5, 7 + -mouse.y * 5, 14);

    // Animate Objects
    dodo.rotation.set(elapsedTime * 3, elapsedTime * 3, elapsedTime * 3);
    cube1.rotation.set(elapsedTime, elapsedTime, elapsedTime);
    cube2.rotation.set(elapsedTime * 2, elapsedTime * 2, elapsedTime * 2);
    donut.rotation.set(elapsedTime / 2, elapsedTime * 2, elapsedTime * 3);

    // Update controls
    controls.update();

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
  };

  tick();
};
