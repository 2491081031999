import Landing from "../pages/Landing";
import Products from "../pages/Products";
import Team from "../pages/Team";
export const routes = [
  {
    path: "/",
    name: "landing",
    Component: Landing,
  },
  {
    path: "/products",
    name: "Products",
    Component: Products,
  },
  {
    path: "/team",
    name: "team",
    Component: Team,
  },
];
