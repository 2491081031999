import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import gsap from "gsap";

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()
export const runWorld = () => {
  // Canvas
  const canvas = document.querySelector("canvas.webgl3");
  const container = document.getElementById("worldcontainer");
  const section = document.getElementById("worldwrapper");
  // container.setAttribute(
  //   "style",
  //   "height:" + (window.innerHeight - 300) + "px"
  // );

  // Scene
  const scene = new THREE.Scene();

  /**
   * Models
   */
  const gltfLoader = new GLTFLoader();

  const clock = new THREE.Clock();
  const group = new THREE.Group();

  // Load Earth glb
  gltfLoader.load("./models/world/earth.glb", (earth) => {
    earth.scene.traverse(function (node) {
      if (node.isMesh) {
        node.receiveShadow = true;
        node.castShadow = true;
      }
    });
    earth.scene.receiveShadow = true;
    earth.scene.scale.set(130, 130, 130);

    var box = new THREE.Box3().setFromObject(earth.scene);
    box.getCenter(earth.scene.position);
    earth.scene.position.multiplyScalar(-1);

    var pivot = new THREE.Group();
    group.add(pivot);
    pivot.add(earth.scene);
    pivot.position.set(-3, 4, 0);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      earth.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;

      pivot.rotation.y = elapsedTime;

      window.requestAnimationFrame(tick);
    };
    tick();
  });

  // Load Red Tube glb
  gltfLoader.load("./models/world/redtube.glb", (redtube) => {
    redtube.scene.scale.set(25, 25, 25);
    redtube.scene.position.z = 0;
    group.add(redtube.scene);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      redtube.scene.position.y = Math.sin(elapsedTime * 3) * 0.15;

      window.requestAnimationFrame(tick);
    };

    tick();
  });

  // Load Blue Tube glb
  gltfLoader.load("./models/world/bluetube.glb", (bluetube) => {
    bluetube.scene.position.x = 1;
    bluetube.scene.scale.set(25, 25, 25);
    bluetube.scene.position.z = 0;
    group.add(bluetube.scene);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      bluetube.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;

      window.requestAnimationFrame(tick);
    };

    tick();
  });

  // Load Phone glb
  gltfLoader.load("./models/world/phone.glb", (phone) => {
    phone.scene.traverse(function (node) {
      if (node.isMesh) {
        node.castShadow = true;
        node.receiveShadow = true;
        node.position.set(0.02, -0.04, -0.07);
      }
    });
    phone.scene.scale.set(25, 25, 25);

    group.add(phone.scene);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      phone.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;

      window.requestAnimationFrame(tick);
    };

    tick();
  });

  // Load Card 1 glb
  gltfLoader.load("./models/world/card1.glb", (card1) => {
    card1.scene.scale.set(2.5, 2.5, 2.5);
    group.add(card1.scene);
    card1.scene.traverse(function (node) {
      if (node.isMesh) {
        node.position.set(0.1, 0.1, 0);
        node.castShadow = true;
      }
    });
    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      card1.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;
      card1.scene.position.z = Math.cos(elapsedTime * 3) * 0.05;

      window.requestAnimationFrame(tick);
    };

    tick();
  });

  // Load Card 2 glTF
  gltfLoader.load("./models/world/card2.glb", (card2) => {
    card2.scene.traverse(function (node) {
      if (node.isMesh) {
        node.position.set(0.1, 0.1, 0);
        node.castShadow = true;
      }
    });

    card2.scene.scale.set(2.5, 2.5, 2.5);
    group.add(card2.scene);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      card2.scene.position.y = Math.sin(elapsedTime * 3) * 0.1;
      card2.scene.position.z = Math.sin(elapsedTime * 3) * 0.05;

      window.requestAnimationFrame(tick);
    };

    tick();
  });

  // Load Molecule 1 glb
  gltfLoader.load("./models/world/molecule1.glb", (molecule1) => {
    molecule1.scene.scale.set(2.5, 2.5, 2.5);
    molecule1.scene.position.set(-8, 0, -25);
    group.add(molecule1.scene);

    var clickCount = 0;

    function incrementCount() {
      clickCount++;
    }
    function first(event) {
      if (clickCount % 2 === 1) {
        gsap.to(molecule1.scene.position, {
          duration: 0.4,
          delay: 0.01,
          x: 0,
          y: 0,
          z: 0,
        });
      }
    }

    function second() {
      if (clickCount % 2 === 0) {
        gsap.to(molecule1.scene.position, {
          duration: 0.75,
          delay: 0.01,
          x: -8,
          y: 0,
          z: -25,
        });
      }
    }
    section.addEventListener("click", incrementCount);
    section.addEventListener("click", first);
    section.addEventListener("click", second);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      molecule1.scene.position.y = Math.sin(elapsedTime * 3) * 0.3;
      molecule1.scene.position.x =
        (Math.sin(elapsedTime * 5) + Math.sin(elapsedTime * 2.5)) * 0.07;
      molecule1.scene.rotation.y =
        (Math.sin(elapsedTime * 5) + Math.sin(elapsedTime * 2.5)) * 0.07;

      window.requestAnimationFrame(tick);
    };

    tick();
  });

  // Load Molecule 2 glb
  gltfLoader.load("./models/world/molecule2.glb", (molecule2) => {
    molecule2.scene.scale.set(2.5, 2.5, 2.5);
    molecule2.scene.position.set(-8, 0, -25);
    group.add(molecule2.scene);

    var clickCount = 0;

    function incrementCount() {
      clickCount++;
    }
    function first(event) {
      if (clickCount % 2 === 1) {
        gsap.to(molecule2.scene.position, {
          duration: 0.4,
          delay: 0.05,
          x: 0,
          y: 0,
          z: 0,
        });
      }
    }

    function second() {
      if (clickCount % 2 === 0) {
        gsap.to(molecule2.scene.position, {
          duration: 0.75,
          delay: 0.05,
          x: -8,
          y: 0,
          z: -25,
        });
      }
    }
    section.addEventListener("click", incrementCount);
    section.addEventListener("click", first);
    section.addEventListener("click", second);

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      molecule2.scene.position.y = Math.sin(elapsedTime * 3) * 0.3;
      molecule2.scene.position.x =
        (Math.sin(elapsedTime * 4) + Math.sin(elapsedTime * 2.5)) * 0.07;
      molecule2.scene.rotation.y =
        (Math.sin(elapsedTime * 4) + Math.sin(elapsedTime * 2.5)) * 0.07;

      window.requestAnimationFrame(tick);
    };

    tick();
  });
  group.position.y = -3;
  group.position.x = -0.5;
  group.scale.set(1.6, 1.6, 1.6);
  scene.add(group);

  /**
   * Lights
   */
  // Ambient Light
  const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
  scene.add(ambientLight);

  // Directional Light
  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  directionalLight.position.set(0.283, 4.37, 2.956);
  scene.add(directionalLight);

  /**
   * Sizes
   */
  const sizes = {
    width: container.offsetWidth,
    height: container.offsetHeight,
  };

  window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = container.offsetWidth;
    sizes.height = container.offsetHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });

  /**
   * Camera
   */
  // Base camera
  const camera = new THREE.PerspectiveCamera(
    75,
    sizes.width / sizes.height,
    0.1,
    20
  );
  camera.position.set(7, 7, 10);
  scene.add(camera);

  // Controls
  const controls = new OrbitControls(camera, canvas);
  controls.enableRotate = false;
  controls.enablePan = false;
  controls.enableZoom = false;
  controls.target.set(0, 0.75, 0);
  controls.enableDamping = true;

  /**
   * Renderer
   */
  const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true,
  });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.setClearColor(0x000000, 0);

  /**
   * Shadows
   */
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;

  directionalLight.castShadow = true;
  directionalLight.shadow.mapSize.set(1024, 1024);
  directionalLight.shadow.camera.far = 15;
  directionalLight.shadow.camera.left = -7;
  directionalLight.shadow.camera.top = 7;
  directionalLight.shadow.camera.right = 7;
  directionalLight.shadow.camera.bottom = -7;

  // Pointer Move Event
  section.addEventListener("pointermove", onpointermove, false);

  const mouse = new THREE.Vector2();

  function onpointermove(event) {
    mouse.x = (event.clientX / section.offsetWidth) * 2 - 1;
    mouse.y = -(event.clientY / section.offsetHeight) * 2 + 1;
  }

  /**
   * Animate
   */
  const tick = () => {
    //Parallax Animation
    camera.position.set(6 + -mouse.x * 5, 7 + -mouse.y * 5, 14);

    // Update controls
    controls.update();

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
  };

  tick();
};
