import React from "react";
import { useHistory } from "react-router-dom";

const Header = () => {
  const history = useHistory();
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark  fixed-top"
      style={{
        background: "transparent !important",
        borderBottom: "1px solid #4d5054",
        padding: "0.5rem 4%",
      }}
    >
      <div className="navbar-brand">
        <img src="img/Frame.png" alt="img"></img>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li
            data-toggle="tab"
            className={
              history.location.pathname === "/"
                ? "nav-item active my-2 my-sm-0"
                : "nav-item my-2 my-sm-0"
            }
            onClick={() => history.push("/")}
          >
            <a className="nav-link">Metodbox AR Nedir?</a>
          </li>
          <li
            data-toggle="tab"
            className={
              history.location.pathname === "/products"
                ? "nav-item active my-2 my-sm-0"
                : "nav-item my-2 my-sm-0"
            }
            onClick={() => history.push("/products")}
          >
            <a className="nav-link">Ürünlerimiz</a>
          </li>
          <li
            data-toggle="tab"
            className={
              history.location.pathname === "/team"
                ? "nav-item active my-2 my-sm-0"
                : "nav-item my-2 my-sm-0"
            }
            onClick={() => history.push("/team")}
          >
            <a className="nav-link">Biz Kimiz?</a>
          </li>
        </ul>
        <button
          id="download-btn"
          className="btn btn-light my-2 my-sm-0 download-btn"
          type="submit"
        >
          mbx AR indir
        </button>
      </div>
    </nav>
  );
};

export default Header;
