import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { routes } from "../../constants/routes";

const MainRouter = (props) => {
  return (
    <Switch>
      {routes.map(({ path, name, Component }, key) => (
        <Route
          key={key}
          exact
          path={path}
          render={(props) => {
            return <Component {...props} />;
          }}
        />
      ))}
    </Switch>
  );
};

export default withRouter(MainRouter);
