import React, { useState } from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneIcon from '@mui/icons-material/Phone';
import PrintIcon from '@mui/icons-material/Print';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const contactInfo = [
  { title: "(+90) 312 265 0003", Component: PhoneIcon, classes: "contact "  },
  { title: "(+90) 312 265 0103", Component: PrintIcon, classes: "contact "  },
  { title: "info@bktomorrow.com", Component: AlternateEmailIcon, classes: "contact highlight" },
];

const Team = () => {
  return (
    <div className="main-container" style={{ padding: "85px", height: "100%" }}>
      <div className="section-container static-page ">
        <div className="row">
          <div className="limited col col-l-6" style={{ margin: "auto 5%" }}>
            <header className="icon-header">
              <p>Biz Kimiz?</p>
            </header>

            <div>
              Sektörü yakından takip ediyor, ihtiyaç duyulacak iş fikirlerini en
              iyi şekilde ve doğru zamanda üretiyoruz.
            </div>
            <div className="highlighted-text">
              Geleceği Birlikte Oluşturmak İstiyoruz
            </div>
            <div>
              Bahçeşehir Koleji’nin, uygulamış olduğu misyon doğrultusunda; BK
              tomorrow’da geleceğin teknolojisine yön verecek, ülkemizi küresel
              ölçekte teknoloji liderleri arasında konumlandıracak genç bilim
              adamlarını yetiştirmek üzere uyguladığı eğitim sistemini,
              geliştireceği ürünler ile genç nesilleri cesaretlendirmeyi
              istemektedir. 
              <hr></hr>
              <p>Türkiye’den Uluslararası arenaya ulaşmak isteyen
              teknolojik altyapısı güçlü, yarınları hayal eden yatırım ve
              girişim teşviklerini öne çıkartan, fikirlere ve gelişmelere bir
              sonraki günden önce sahip olmayı arzu eden, çocukları ile birlikte
              yarını inşa etmek isteyen ebeveynlerin, eğitimciler ile iş birliği
              ile daha iyi nesilleri yetiştirmek için bir araya gelebileceği
              sosyal bir ürün ortaya çıkartmak amacıyla çalışmalarına
              başlamıştır.
              </p>
            </div>
            <div>
              <a href="https://bktomorrow.com/"><img src="img/bkMobil.png"></img></a>
              <hr></hr>
              <p>Copyrights © 2020 Tüm hakları saklıdır.</p>
            </div>
          </div>
          <div
            className="col-12 col-md"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="contact-container">
            <div>
              <strong> Merkez Ofis:</strong>
             
              <p style={{color:"rgba(255, 255, 255, 0.7)"}}>Ankara Teknoloji Geliştirme Bölgesi,
              Cyberplaza C Blok 3.kat BK Mobil,
              No:354 06800 Bilkent Çankaya / ANKARA
              </p>
            </div>
            <div style={{marginTop:"10%", marginBottom:"20%"}}>
              {contactInfo.map(({title,classes ,Component}, index)=>(
              <div className={classes} key={index}><Component />{`  `}{title}</div>
            ))}
             
            </div>
            
            <div className="social-contact-container">
              <p style={{color:"rgba(255, 255, 255, 0.7)"}}>Bizi takip edin;</p>
              <InstagramIcon className="social-contact" style={{color: 'white'}} onClick={() => window.open('https://www.instagram.com/metodboxcom/')}/>
              <TwitterIcon className="social-contact" style={{color: 'white'}} onClick={() => window.open('https://twitter.com/bk_mobil')}/>
              <FacebookIcon className="social-contact" style={{color: 'white'}} onClick={() => window.open('https://www.facebook.com/BKTomorrow/')}/>
              <LinkedInIcon className="social-contact" style={{color: 'white'}} onClick={() => window.open('https://www.linkedin.com/company/bk-mobil/')}/>
            </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
